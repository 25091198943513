// src/components/NavBar.js
import React from 'react';

import { AppBar, Toolbar, Typography, Link } from '@mui/material';

const NavBar = () => {
  return (
    <AppBar position="fixed" color="transparent" sx={{ boxShadow: 0 }}>
        <Toolbar>
            <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
                <Link color="inherit" underline="hover" href="/">HeroParty</Link>
            </Typography>
        </Toolbar>
    </AppBar>
  );
};

export default NavBar;
