// src/App.js

import NavBar from './components/NavBar';
import About from './components/About'; 
import Landing from './components/Landing'; 

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  website: {
    color: "#328396", 
    backgroundColor: "#ebf2ff",
  },
}));

function App() {
  const classes = useStyles();
  return (
    <div className={classes.website}>
      <NavBar/>
      <Router>
        <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/about" element={<About />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
