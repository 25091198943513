// src/components/LandingPage.js
import React from 'react';
import { Typography, Link, Container, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  heroSection: {
    minHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    fontSize: '48px',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '24px',
    margin: '20px 0',
  },
  ctaButton: {
    marginTop: '20px',
  },
  featureSection: {
    padding: '40px 0',
  },
  featureItem: {
    textAlign: 'center',
  },
  footer: {
    padding: '20px 0',
    marginTop: '50px',
    textAlign: 'center',
  },
}));

const Landing = () => {
  const classes = useStyles();

  return (
    <div>
      {/* Hero Section */}
      <Box className={classes.heroSection}>
        <Typography variant="h1" className={classes.title}>
            Where the Zero Party is at!
        </Typography>
        <Typography variant="h5" className={classes.subtitle}>
            Unlock the power of <b>your</b> data with HeroParty
        </Typography>
      </Box>

      {/* Features Section */}
      <Container maxWidth="lg" className={classes.featureSection}>
        <Typography variant="h4" align="center" gutterBottom>
            <Link color="inherit" underline="hover" href="/about">About us</Link>
        </Typography>
      </Container>

      {/* Call to Action Section */}
      <Box className={classes.heroSection}>
        <Typography variant="h4" align="center" gutterBottom>
          Want to know more? 
        </Typography>
        <Typography variant="h5" align="center" gutterBottom>
            <Link color="inherit" underline="hover" href="mailto:ceo.heroparty@gmail.com">Reach out to us here</Link>
        </Typography>
      </Box>

      {/* Footer */}
      <footer className={classes.footer}>
        <Container maxWidth="lg">
          <Typography variant="body1">
            HeroParty {new Date().getFullYear()} 
          </Typography>
        </Container>
      </footer>
    </div>
  );
};

export default Landing;
