// src/components/About.js
import React from 'react';

import { Typography, Container } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    featureSection: {
        textAlign: 'left',
        minHeight: '100vh', 
    },
}));

const About = () => {
    const classes = useStyles();
    return (
        <Container maxWidth="lg" className={classes.featureSection}>
            <Typography variant="h2" align="center" gutterBottom>
                About Us
            </Typography>
            <Typography variant="p">
                What is data but a point in a story. <b>Own Your Story</b>.
            </Typography>
            <Typography variant="h3">Why you need a hero</Typography>
            <Typography variant="p">
                We want to make your life easier by unlocking the data that companies hold on you by utilising GDPR's Subject Access Request.  
            </Typography>
            <Typography variant="h3"> What is a Subject Access Request?</Typography>
            <Typography variant="p">
                A Subject Access Request (SAR) is a formal request made by an individual to a company, asking for access to the personal data the company holds about them.
                <br/>
                Under data protection laws, such as the General Data Protection Regulation (GDPR) in the European Union and the Data Protection Act 2018 in the UK, 
                individuals have the right to know what personal data is being collected, how it's being used, and who it’s shared with.
            </Typography>
        </Container>
    );
};

export default About;
